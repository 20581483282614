<template>
  <div>
    <headPage />
    <!-- 巧用灯光 -->
    <div class="box1" style="position: reletive">
      <img class="bg1" src="../assets/image/intelligentLighting/image1.png" />
      <div>
        <div class="info" data-aos="fade-right">
          <div class="title1">巧用灯光</div>
          <div class="title2">营造空间氛围</div>
          <div class="title3">智能灯光</div>
          <div class="line"></div>
          <div class="title4">
            和传统灯光相比，智能控制下的灯光不仅仅是提供实现明暗变换、
            远程控制，灯光场景一键切换这么简单。
          </div>
          <div class="title5">
            MH享家会安排灯光设计师，基于
            “人性化使用、光线舒适度”进行专业化的灯光设计，满足客户的场景化灯光需求。
          </div>
        </div>
      </div>
    </div>
    <!-- 3大特点 -->
    <div style="position: reletive; overflow: hidden">
      <img
        style="display: block; width: 100%"
        src="../assets/image/intelligentLighting/image2.png"
      />
      <div class="info2">
        <img
          class="img1"
          style="display: block"
          src="../assets/icon/image9.png"
        />
        <img
          class="img2"
          style="display: block"
          src="../assets/icon/image7.png"
        />
        <img
          class="img3"
          style="display: block"
          src="../assets/icon/image8.png"
        />
        <div class="title6-1">更专业</div>
        <div class="title6-2">更自然</div>
        <div class="title6-3">更智能</div>
        <div class="title6-4">人性化灯光设计</div>
        <div class="title6-5">光感变化柔和丝滑</div>
        <div class="title6-6">场景一键切换</div>
      </div>
    </div>
    <!-- 场景切换 -->
    <div class="box3" style="position: reletive">
      <img
        style="display: block; width: 100%"
        src="../assets/image/intelligentLighting/image3.png"
      />
      <div>
        <div class="info3">
          <div data-aos="fade-right">
            <div class="title7-1">场景切换</div>
            <div class="title7-2">一键可达</div>
          </div>
          <div class="title7-3">方寸之间<br />包罗万象</div>
          <div class="title7-4" data-aos="fade-left">
            根据客户需求和生活习惯自定义设置场景模式，一键构筑生活、工作、娱乐、会客的场景，控制模式里涉及到的所有灯具，不需要逐一开关灯和调光，让你的生活
          </div>
        </div>
      </div>
    </div>
    <!-- 停留区域 -->
    <div class="box4" style="position: reletive">
      <img
        style="display: block; width: 100%"
        src="../assets/image/intelligentLighting/image4.png"
      />
      <div>
        <div class="info4" data-aos="fade-right">
          <div class="title8-1">常时间</div>
          <div class="title8-2">停留区域</div>
          <div class="line2"></div>
          <div class="title8-3">会客模式</div>
          <div class="title8-4">观影模式</div>
          <div class="title8-5">
            朋友来访时，一键切换会客模式。客厅暖
            光亮起，窗帘拉开，若有似无的背景音乐响起。
          </div>
          <div class="title8-6">
            家人观影时。一键切预影音模式，上客厅灯光关闭、窗帘闭合、背景音乐关闭，一起沉浸视觉大片中。
          </div>
        </div>
      </div>
    </div>
    <!-- 不常停留 -->
    <div style="position: reletive">
      <img
        style="display: block; width: 100%"
        src="../assets/image/intelligentLighting/image5.png"
      />
      <div>
        <div class="info5" data-aos="fade-left">
          <div class="title9-1">不常停留</div>
          <div class="title9-2">区域</div>
          <div class="line3"></div>
          <div class="title9-3">夜起模式</div>
          <div class="title9-4">
            门廊、门厅、过道、楼梯设有人休感应器，通过光照度感应器的判断可以实现晚上人来灯亮，人走灯灭。
          </div>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.box1 {
  margin-top: 18px;
}
.box4 {
  margin-top: -3px;
}
.box3 {
  transform: translateY(-2px);
}
.bg1 {
  display: block;
  width: 100%;
  z-index: -1;
  margin-top: -19px;
}
.info {
  text-align: left;
  left: 130px;
  top: 270px;
  z-index: 999;
  position: absolute;
}
.title1 {
  font-size: 58px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
}
.title2 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 57px;
  color: #d0ac32;
  margin-top: -10px;
}
.title3 {
  font-size: 29px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  left: 5px;
  top: 168px;
}
.title4 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  top: 306px;
  position: absolute;
  width: 565px;
  left: 1px;
  justify-content: space-between;
  text-align: justify;
  font-size: 16px;
}
.title5 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  top: 380px;
  position: absolute;
  width: 565px;
  left: 1px;
  justify-content: space-between;
  text-align: justify;
  font-size: 16px;
}
.line {
  width: 52px;
  background-color: white;
  position: absolute;
  top: 252px;
  height: 1px;
  left: 6px;
}
.info2 {
  position: absolute;
  top: 1267px;
  left: 204px;
}
.info3 {
  position: absolute;
  top: 45px;
  left: 135px;
  z-index: 999;
}
.info4 {
  position: absolute;
  top: 2409px;
  text-align: left;
  left: 137px;
}
.info5 {
  position: absolute;
  top: 3102px;
  text-align: left;
  left: 901px;
}
.img1 {
  width: 60%;
}
.img2 {
  width: 45%;
  position: absolute;
  left: 405px;
  top: 8px;
}
.img3 {
  width: 50%;
  position: absolute;
  left: 797px;
  top: 8px;
}
.title6-1 {
  width: 141px;
  font-family: "SourceHanSansCN-Blod";
  font-size: 40px;
  color: #d0ac32;
  left: 77px;
  position: absolute;
  top: 81px;
}
.title6-2 {
  width: 141px;
  font-family: "SourceHanSansCN-Blod";
  font-size: 40px;
  color: #d0ac32;
  left: 484px;
  position: absolute;
  top: 81px;
}
.title6-3 {
  width: 141px;
  font-family: "SourceHanSansCN-Blod";
  font-size: 40px;
  color: #d0ac32;
  left: 898px;
  position: absolute;
  top: 81px;
}
.title6-4 {
  font-family: "SourceHanSansCN-Light";
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: 134px;
  width: 292px;
}
.title6-5 {
  font-family: "SourceHanSansCN-Light";
  font-size: 41px;
  color: #fff;
  position: absolute;
  top: 133px;
  width: 336px;
  left: 387px;
}
.title6-6 {
  font-family: "SourceHanSansCN-Light";
  font-size: 41px;
  color: #fff;
  position: absolute;
  top: 133px;
  width: 336px;
  left: 800px;
}
.title7-1 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
}
.title7-2 {
  color: #d0ac32;
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
  position: absolute;
  left: 230px;
  width: 224px;
  top: 0px;
}
.title7-3 {
  color: #d0ac32;
  font-family: "SourceHanSansCN-Medium";
  position: absolute;
  left: 1106px;
  width: 120px;
  font-size: 28px;
  top: 127px;
}
.title7-4 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  position: absolute;
  left: 779px;
  width: 457px;
  top: 421px;
  text-align: left;
  font-size: 16px;
}
.title8-1 {
  color: #d0ac32;
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
}
.title8-2 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
}
.title8-3 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 29px;
  position: absolute;
  top: 235px;
}
.title8-4 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 29px;
  position: absolute;
  top: 235px;
  left: 426px;
  width: 116px;
}
.title8-5 {
  color: #b0acad;
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  position: absolute;
  top: 299px;
  left: 0px;
  width: 288px;
}
.title8-6 {
  color: #b0acad;
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  position: absolute;
  top: 297px;
  left: 427px;
  width: 304px;
}
.line2 {
  width: 52px;
  height: 1px;
  background-color: white;
  top: 189px;
  position: absolute;
}
.title9-1 {
  color: #d0ac32;
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
}
.title9-2 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 56px;
  position: absolute;
  top: 0px;
  left: 228px;
  width: 113px;
}
.title9-3 {
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
  font-size: 29px;
  position: absolute;
  top: 169px;
}
.title9-4 {
  color: #b0acad;
  font-family: "SourceHanSansCN-Light";
  font-size: 16px;
  position: absolute;
  top: 234px;
  left: 2px;
  width: 317px;
  text-align: left;
}
.line3 {
  width: 52px;
  height: 1px;
  background-color: white;
  top: 116px;
  position: absolute;
  left: 1px;
}
@media (max-width: 500px) {
  .info2 {
    img {
      width: 15%;
    }
  }
  .info3 {
    top: 1848px;
  }
  .info4 {
    top: 2509px;
  }
}
</style>